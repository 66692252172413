import React, { useState, useEffect } from 'react'

import { t, languageCodesFromLocales, currentLanguage, languageFromLocale } from '../utils'

import { signOut } from '../services/auth'
import config from '../config.json'

const Navbar = (props) => {

  const { history, email, refreshInteractions, userData, isLoading, setLocale, partner } = props

  useEffect(() => {
    refreshInteractions()
  }, [email, isLoading])

  const navigate = (path) => () => {
    history.push(path)
  }

  const otherLocale = () => {
    if (partner) {
      for (let i in partner.locales) {
        if (currentLanguage() !== languageFromLocale(partner.locales[i]).code) {
          return partner.locales[i]
        }
      }
    }
    return null
  }

  const selectLocale = (loc) => {
    console.log('loc :>> ', loc);
    setLocale(loc)
  }

  const renderLogo = () => {
    switch (config.partner) {
      case 'lv':
        return (
          <a 
            href={currentLanguage() === 'fr' ? 'https://www.legalvillage.be/fr/professionnels/' : 'https://www.legalvillage.be/nl/ondernemers'} 
            aria-current="page" 
            className="brand w-nav-brand w--current"
          >
            <img src="images/Group-6.svg" alt="" className="brand__img" />
          </a>
        )
      case 'axa':
        return (
          // <a href="#" onClick={navigate('/')} aria-current="page" className="brand w-nav-brand w--current">
          //   <img src="images/axa-logo.svg" alt="" className="axa__img"/>
          // </a>
          <a 
            href={currentLanguage() === 'fr' ? 'https://www.legalvillage.be/fr/professionnels/' : 'https://www.legalvillage.be/nl/ondernemers'} 
            aria-current="page" 
            className="brand w-nav-brand w--current"
          >
            <img src="images/Group-6.svg" alt="" className="brand__img" />
          </a>
        )
    }
  }

  const renderGroup = () => {
    if (currentLanguage() === 'fr') {
      return (
        <div className="dropdown-toggle-3 w-dropdown-toggle">
          <span>
            Fait partie du groupe &nbsp;
          </span>
          <span className="bold">
            AXA
          </span>
        </div>
      )
    } else {
      return (
        <div className="dropdown-toggle-3 w-dropdown-toggle">
          <span>
            Onderdeel van &nbsp;
          </span>
          <span className="bold">
            AXA &nbsp;
          </span>
          <span>
            group
          </span>
        </div>
      )
    }
  }

  const renderContent = () => {
    switch (config.partner) {
      case 'lv':
        return (
          <nav role="navigation" className="nav__menu w-nav-menu">
            <div className="nav-links-wrap nav-links-wrap--mid">
              <div data-hover="" data-delay="0" data-w-id="c8a03164-4feb-904b-c3cf-a9b9e5d40ed9" className="nav__dropdown-wrap w-dropdown">
                <div className="dropdown-toggle-2 nav__link w-dropdown-toggle">
                  <div>{t('navbar.mls')}</div>
                  <img src="images/right-arrow.svg" alt="" className="nav__dropdown-img" />
                </div>
                <nav className="nav__dropdown-content w-dropdown-list">
                  <a href={t('navbar.mmb_link')} className="nav-dropdown__link w-inline-block">
                    <img src="images/conseils.svg" alt="" className="nav-dropdown__img" />
                    <div className="nav-dropdown__text"><div className="nav-dropdown__link-title">{t('navbar.mmb')}</div>
                      <p className="nav-dropdown__desc">{t('navbar.mmb_description')}</p>
                    </div><div className="v-gray-line"></div>
                  </a>
                  <a href={t('navbar.mld_link')} className="nav-dropdown__link w-inline-block">
                    <img src="images/My-legal-doc.svg" alt="" className="nav-dropdown__img" />
                    <div className="nav-dropdown__text"><div className="nav-dropdown__link-title">{t('navbar.mld')}</div>
                      <p className="nav-dropdown__desc">{t('navbar.mld_description')}</p></div>
                    <div className="v-gray-line"></div>
                  </a>
                  <a href={t('navbar.mle_link')} onClick={navigate('/')} className="nav-dropdown__link w-inline-block">
                    <img src="images/my-legal-expert.svg" alt="" className="nav-dropdown__img" />
                    <div className="nav-dropdown__text"><div className="nav-dropdown__link-title">{t('navbar.mle')}</div>
                      <p className="nav-dropdown__desc">{t('navbar.mle_description')}</p></div>
                  </a>
                </nav>
              </div>
              <a data-w-id="2ac4be7d-8f22-d95c-2f8b-9e111098f460" href={t('navbar.protection_link')} className="nav__link w-inline-block">
                <div className="gray-bg"></div>
                <p className="nav__title">{t('navbar.protection')}<br /></p>
              </a>
            </div>
            <div className="nav-links-wrap right-align">
              <a data-w-id="7d509131-a7b2-93d0-9d36-f89a76be2671" href={t('navbar.private_link')} className="nav__link nav__link--mr w-inline-block">
                <div className="gray-bg"></div>
                <p className="nav__title">{t('navbar.private')}<br /></p>
              </a>
              <a data-w-id="a5071e87-adc3-c10b-13f6-18424c92e7c0" href={t('navbar.contact_link')} className="nav__link nav__link--mr w-inline-block">
                <div className="gray-bg"></div>
                <p className="nav__title">{t('navbar.contact')}<br /></p>
              </a>
              {/* { !!otherLocale() ? ( */}
              <a onClick={() => selectLocale(otherLocale())} href="#" className="nav__link nav__link--margin w-inline-block">
                <div className="gray-bg"></div>
                <p className="nav__title">{currentLanguage() === 'fr' ? 'NL' : 'FR'}<br /></p>
              </a>
              {/* ) : null } */}
            </div>
          </nav>
        )
      case 'axa':
        return (
          <nav role="navigation" className="nav__menu w-nav-menu">
            <div className="nav-links-wrap nav-links-wrap--mid">
              <div data-hover="" data-delay="0" data-w-id="c8a03164-4feb-904b-c3cf-a9b9e5d40ed9" className="nav__dropdown-wrap w-dropdown">
                {renderGroup()}
                {/* <div className="dropdown-toggle-2 w-dropdown-toggle">
                  <div>{t('navbar.als')}</div>
                  <img src="images/right-arrow.svg" alt="" className="nav__dropdown-img"/>
                </div> */}
                {/* <nav className="nav__dropdown-content w-dropdown-list">
                <a href={t('navbar.mmb_link')} className="nav-dropdown__link w-inline-block">
                  <img src="images/conseils.svg" alt="" className="nav-dropdown__img"/>
                  <div className="nav-dropdown__text"><div className="nav-dropdown__link-title">{ t('navbar.mmb') }</div>
                  <p className="nav-dropdown__desc">{ t('navbar.mmb_description') }</p>
                  </div><div className="v-gray-line"></div>
                </a>
                <a href={t('navbar.mld_link')} className="nav-dropdown__link w-inline-block">
                  <img src="images/My-legal-doc.svg" alt="" className="nav-dropdown__img"/>
                  <div className="nav-dropdown__text"><div className="nav-dropdown__link-title">{ t('navbar.mld') }</div>
                  <p className="nav-dropdown__desc">{ t('navbar.mld_description') }</p></div>
                  <div className="v-gray-line"></div>
                </a>
                <a href={t('navbar.mle_link')} onClick={navigate('/')} className="nav-dropdown__link w-inline-block">
                  <img src="images/my-legal-expert.svg" alt="" className="nav-dropdown__img"/>
                  <div className="nav-dropdown__text"><div className="nav-dropdown__link-title">{ t('navbar.mle') }</div>
                  <p className="nav-dropdown__desc">{ t('navbar.mle_description') }</p></div>
                </a>
              </nav> */}
              </div>
            </div>
            <div className="nav-links-wrap right-align">
              {/* { !!otherLocale() ? ( */}
              <a onClick={() => selectLocale(otherLocale())} href="#" className="nav__link nav__link--margin w-inline-block">
                <div className="gray-bg"></div>
                <p className="nav__title">{languageFromLocale(otherLocale()).code.toUpperCase()}<br /></p>
              </a>
              {/* ) : null } */}
            </div>
          </nav>
        )
    }
  }

  return (
    <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" className="nav navbar w-nav">
      <div className="container container--nav">{renderLogo()}
        {renderContent()}
        <div className="menu-button w-nav-button">
          <div data-w-id="ef1dd36a-6c76-0251-eaf1-171dc03ac87a" className="w-icon-nav-menu"></div><img src="images/close-btn.svg" alt="" className="close-btn" /></div>
      </div>
    </div>
  )
}

export default Navbar