import React, { useState } from 'react'
import { t, currentLanguage } from '../utils'
import { validateVoucher } from '../services/functions'

const SolutionBlock = (props) => {

  const { solution, index, onSelect, features, startLoading, stopLoading, showSnackbar, onVoucherApplied } = props

  const [voucherCode, setVoucherCode] = useState('')
  const [newPrice, setNewPrice] = useState(-1)

  const validateCode = async () => {
    startLoading('validating')
    let result = await validateVoucher(voucherCode, solution)
    console.log(result)
    stopLoading('validating')
    if (result.error) {
      setNewPrice(-1)
      onVoucherApplied(voucherCode, -1)
      showSnackbar({ text: t('voucher.invalid_code'), color: 'error' })
      return
    }
    let priceAfterReduction = result.price
    setNewPrice(parseFloat(`${priceAfterReduction.toFixed(1)}`))
    onVoucherApplied(voucherCode, priceAfterReduction)
  }

  const renderPrice = () => {
    if (newPrice === -1) {
      return <span>{solution.price}€</span>
    } else {
      return <span><span className="header-strike-through">{solution.price}€&nbsp;</span><b>{newPrice}€</b></span>
    }
  }

  return (
    <div key={`solution_${index}`} className="col">
      <div data-w-id="e0576393-7a2e-2da1-34d3-a0edfd69f70d" className="col__formules-wrap _1">
        <h4 className="h4">{solution.title[currentLanguage()]}<br /></h4>
        <div className="price-box">
          <h1 className="h1 h1--price">{renderPrice()}&nbsp;<span className="htva-label">{t('price.including_vat')}</span><br /></h1>
        </div>
        <div className="price_offer">{solution.offer[currentLanguage()]}</div>
        <h5 className="col__h5">{solution.description[currentLanguage()]}<br /></h5>
        <div className="list">
          {/* { features.map((feature, fi) => {
            return (
              <div key={`solution_${index}_feature_${fi}`} className="list__item"><img src={solution.features[fi] ? 'images/check-1.svg' : 'images/close-gray.svg'} alt="" className="list__img" />
                <h5 className="list__h5">{ feature[currentLanguage()] }<br/></h5>
              </div>
            )
          })} */}
          <div className="list__item"><img src={solution.features[0] ? 'images/check-1.svg' : 'images/close-gray.svg'} alt="" className="list__img" />
            <h5 className="list__h5">{t('feature.one')}<br /></h5>
          </div>
          <div className="list__item"><img src={solution.features[1] ? 'images/check-1.svg' : 'images/close-gray.svg'} alt="" className="list__img" />
            <h5 className="list__h5">{t('feature.two')}<br /></h5>
          </div>
          { !solution.features[2] ? (
            <div className="list__item"><img src={solution.features[2] ? 'images/check-1.svg' : 'images/close-gray.svg'} alt="" className="list__img" />
              <h5 className="list__h5">{t('feature.three')}<br /></h5>
            </div>
          ) : (
              <div className="list__item"><img src={solution.features[2] ? 'images/check-1.svg' : 'images/close-gray.svg'} alt="" className="list__img" />
                <h5 className="list__h5">{t('feature.four')}<br /></h5>
              </div>
            )}

        </div>
        {solution.price === '0' ? (
          <div className="legalcopy">
            { t('voucher.legal_copy')}
          </div>
        ) : null}
        {(!!onSelect && solution.price !== '0') ? (
          <div className="voucher-button-wrap">
            <input type="text" className="voucher__text-field w-input voucher_code" placeholder={t('price.voucher_placeholder')} value={voucherCode} onChange={e => { setVoucherCode(e.target.value) }} ></input>
            <div onClick={validateCode} className="link-box link-box--voucher w-inline-block link-box--blue">
              <p className="link-box__title voucher_code_button_text">{t('general.apply')}<br /></p>
            </div>
          </div>
        ) : null}

        {!!onSelect ? (
          <div onClick={onSelect(index, solution)} className={`link-box link-box--submit w-inline-block ${solution.recommended ? 'link-box--green' : ''}`}>
            <p className="link-box__title">{t('general.select')}<br /></p>
          </div>
        ) : null}
        {solution.recommended ? (
          <div className="best-choice-wrap">
            <h5 className="h5 h5--green">{t('appointment.best_choice')}<br /></h5>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SolutionBlock