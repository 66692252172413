import React from 'react'
import { t } from '../utils'

const Breadcrumb = (props) => {
  const { path, navigate } = props
  return (
    <div className="breadcrumbs-wrap">
      <div className="breadcrumbs-wrap__bg-left"></div>
      <div className="breadcrumbs-wrap__bg-right"><div className="breadcrumbs-wrap__bg-right_inner"></div></div>
      <>
        <a href="#" onClick={() => navigate(path.length > 0 ? path[0].url : '/')} className="breadcrumb-link breadcrumb-link--np w-inline-block">
          {t('nav.home')}
        </a>
        <p className="breadcrumps-p"><img src="images/breadcrumb-arrow.svg" alt="" /></p>
      </>
      { path.map((step, si) => {
        if(si === 0) {
          return null
        }
        return (
          <>
            <a key={`step_${si}`} href="#" className={`breadcrumb-link ${si === path.length - 1 ? 'w--current' : ''}`} onClick={() => navigate(step.url)}>
              { step.label }
            </a>
          { si < path.length - 1 ? ( <p className="breadcrumps-p"><img src="images/breadcrumb-arrow.svg" alt="" /></p> ) : null }
          </>
          
        )
      })}
    </div>
  )
}

export default Breadcrumb