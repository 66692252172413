import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import TagManager from 'react-gtm-module'
import config from "./config.json";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import './_style/index.scss'

if (config.partner === "lv") {
  const trackers = [
    {
      trackingId: "UA-3057324-47",
      gaOptions: { name: "tracker1" },
    },
    {
      trackingId: "UA-179952362-3",
      gaOptions: { name: "tracker2" },
    },
  ];
  ReactGA.initialize('UA-3057324-47');
  ReactGA.addTrackers(trackers)
  // console.log('react ga initialized***')
  hotjar.initialize("2112776", 6);
  ReactGA.pageview(window.location.pathname + window.location.search);
  TagManager.initialize({ gtmId: 'GTM-5GS5MSB' })
} else if (config.partner === "axa") {
  ReactGA.initialize('UA-160225546-7')
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
