import React, { useEffect, useState, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import { sortedArrayFromObject, currentLanguage, t } from '../utils'
import TextField from '@material-ui/core/TextField'
import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import 'moment/locale/nl'
import 'moment/locale/fr'
import { animateScroll as scroll } from 'react-scroll'

import { currentUser } from '../services/auth'
import FileInput from './FileInput'
import config from '../config.json'

const timeOptions = [
  '09h00',
  '09h30',
  '10h00',
  '10h30',
  '11h00',
  '11h30',
  '12h00',
  '12h30',
  '14h00',
  '14h30',
  '15h00',
  '15h30',
  '16h00',
  '16h30',
]

const SPStep3 = (props) => {

  const { selectedQuestion, showAuth, onSubmit, refreshInteractions, partner, questions } = props

  const [comment, setComment] = useState('')
  const [attachments, setAttachments] = useState([])
  const [date, setDate] = useState(moment())
  const [time, setTime] = useState('')
  const [timesExpended, setTimesExpanded] = useState(false)
  const language = currentLanguage()
  moment.locale(language)

  const fileInputRef = useRef(null)

  useEffect(() => {
    refreshInteractions()
  }, [])

  const selectTime = (option) => () => {
    setTime(option)
  }

  const toggleTimesExpanded = () => {
    setTimesExpanded(!timesExpended)
  }

  const openFileInput = () => {
    if (!!fileInputRef.current) {
      fileInputRef.current.click()
    } else if (!!fileInputRef.click) {
      fileInputRef.click()
    }
  }

  const onFileSubmitted = ({ file, name, type }) => {
    addFile({ file, name, type })
  }

  const addFile = (file) => {
    let att = attachments
    att.push(file)
    setAttachments([...att])
  }

  const removeAttachment = (index) => () => {
    let att = attachments
    att.splice(index, 1)
    setAttachments([...att])
  }

  const isSessionValid = () => {
    if (!date) {
      return false
    } if (!time) {
      return false
    }
    return true
  }

  const submit = () => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    if (!currentUser()) {
      showAuth('login', () => {
        submit()
      }, () => {
        submit()
      })
    } else {
      let completeDateString = `${date.locale(currentLanguage()).format('MM-DD-YYYY')} ${time}`
      let completeDate = moment(completeDateString, 'MM-DD-YYYY HH:mm').valueOf()
      onSubmit(comment, attachments, completeDate)
    }
  }

  const printSelectedDay = () => {
    if (date) {
      let day = date.format('D')
      let weekday = date.locale(currentLanguage()).format('dddd')
      let month = date.locale(currentLanguage()).format('MMMM')
      let year = date.format('YYYY')
      return `${capitalizeWord(weekday)} ${day} ${month} ${year}`
    } else {
      return ''
    }
  }

  const capitalizeWord = (word) => {
    return `${word[0].toUpperCase()}${word.substring(1)}`
  }

  const selectDate = (d) => {
    setDate(d)
    if (!canSelectTime(time, d)) {
      setTime('')
    }
  }

  const canSelectTime = (timeOption, d = date) => {
    let now = moment().valueOf()
    if (d.valueOf() > now) {
      return true
    }
    let timeValue = moment(timeOption, 'HH[h]mm')
    const sessionLengthMinutes = 30
    const sessionLength = sessionLengthMinutes * 60 * 1000
    return now < timeValue - sessionLength
  }

  const isDateUnavailable = (date) => {
    if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
      return true
    }
    if (partner && partner.unavailable_dates) {
      for (let i in partner.unavailable_dates) {
        let ud = partner.unavailable_dates[i]
        let udMoment = moment(ud.value)

        let sameDay = udMoment.date() === date.date()
        let sameMonth = udMoment.month() === date.month()
        let sameYear = udMoment.year() === date.year()
        if (sameDay && sameMonth) {
          if (ud.yearly || sameYear) {
            return true
          }
        }
      }
    }
    return false
  }

  const maxCharacters = 500
  const maxFileSize = 5000000

  return (
    <div className="container container--hero">
      <h3 data-w-id="0e48b1ac-9288-3d3d-ae7b-164a2f0f8aa4" className="h3 h3--bold h3--blue">{`${t('appointment.detail_title')}`}<br /></h3>
      <div className="request-wrap">
        <div className="row">
          <div className="col col--request">
            <div className="request-form w-form">
              <div id="email-form" name="email-form" data-name="Email Form" className="form">
                <h3 className="h3 h3--bold h3--left">{questions ? selectedQuestion === 'other' ? t('dashboard.question_title') : questions[selectedQuestion]?.text[currentLanguage()] : ''}<br /></h3><label htmlFor="Commentaire" className="form__label">{t('dashboard.comment_2')}</label>
                <div className="textarea-wrap"><textarea name="Commentaire" maxLength={maxCharacters} id="Commentaire" data-name="Commentaire" className="textarea w-input" value={comment} onChange={e => { setComment(e.target.value) }}></textarea><div className="form__characters">{maxCharacters - comment.length} {t('dashboard.characters_remaining')}</div></div>
                
                <label htmlFor="Commentaire-2" className="form__label mb-2">{t('dashboard.attachments_2')}<br /></label>

                <Grid container direction="column">
                  {attachments.map((att, ai) => {
                    return (
                      <Grid key={`att_${ai}`} item className="attachment-thumb">
                        <span className="attachment-thumb-label">{`${att.name}`}</span>
                        <IconButton onClick={removeAttachment(ai)} className="attachment-thumb-delete">
                          <CancelIcon color="secondary" fontSize="small" />
                        </IconButton>
                      </Grid>
                    )
                  })}
                </Grid>
                <div className="request-btn-wrap"><input type="submit" value="" data-wait="Please wait..." className="request-btn w-button" />
                  <div data-w-id="259489aa-621a-2ac3-c28d-a52e1273fa43" className="request-cta" onClick={openFileInput}>
                    <div className="text text--small">{t('dashboard.upload_file')}<br /></div><img src="images/🎨-Style.svg" alt="" className="arrow-up-img" />
                  </div>
                </div>
                <div className="form__characters">
                  Max {maxFileSize / 1000000} MB
                </div>
              </div>
            </div>
          </div>
          <div className="col col--request-long">
            <div className="col__col">
              <h4 className="h4 h4--request">{t('general.select_date')}<br /></h4>
              <div style={{ overflow: 'hidden' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Calendar
                    disablePast
                    date={date}
                    onChange={selectDate}
                    shouldDisableDate={isDateUnavailable} />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="col__col _2">
              <div onClick={isSessionValid() ? submit : () => { }} className={`request-submit-button ${!isSessionValid() ? 'disabled' : 'link-box link-box--submit w-inline-block link-box--green profile_save_button'}`}>
                <p className="nav__title">{t('general.confirm')}<br /></p>
              </div>
              <div className="col__text">
                {printSelectedDay()}
                <br />
                {/* { time ? time : '' } */}
              </div>
              {timeOptions.map((option, oi) => {
                if (timesExpended || oi % 2 === 0 || time === option) return (
                  <div key={`time_option_${oi}`} onClick={canSelectTime(option) ? selectTime(option) : () => { }} className={`request-cta request-cta--small request-cta--center w-inline-block ${canSelectTime(option) ? '' : 'disabled'} ${time === option ? 'selected' : ''}`}>
                    <div className="text text--small">{option}</div>
                  </div>
                )
                else return null
              })}
              <div className="col__text col__text--green" onClick={toggleTimesExpanded}>{timesExpended ? t('appointment.view_less_times') : t('appointment.view_more_times')}<br /></div>
            </div>
          </div>
        </div>
      </div>
      <FileInput id="fileInput" fileInputRef={fileInputRef} onFileSubmitted={onFileSubmitted} maxFileSize={maxFileSize} accept="image/png, image/jpg, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/rtf, application/vnd.oasis.opendocument.text" />
    </div>
  )
}

export default SPStep3