import moment from "moment"
import t, { setLanguage } from './strings'

import cookie from 'react-cookies'
import config from '../config.json'
// import ReactGA from 'react-ga';

import constants from './constants.json'

// ReactGA.initialize(config.ga_key);

const LANGUAGE_C_KEY = 'legalexpert__selected_language'
const COOKIES_ACCEPTED_KEY = 'legalexpert__cookies_acc'
const RECAPTCHA_SITE_KEY = '6LcIrh4dAAAAAIA8jVnXHP5_Chv1znTNqWGhwY5I'

const sortedArrayFromObject = (object, sortKey, asc) => {
  let array = []
  for(let key in object) {
    let item = object[key]
    item.id = key
    array.push(item)
  }
  array.sort((a,b) => {
    if(a[sortKey] > b[sortKey]) return asc ? 1 : -1
    if(a[sortKey] < b[sortKey]) return asc ? -1 : 1
    return 0
  })
  return array
}

const printDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

const idFromName = (name) => {
  return name.replace(/\s/g, "")
}

const currentLanguage = () => {
  let language = cookie.load(LANGUAGE_C_KEY)
  if(!language) {
    language = 'fr'
  } 
  return language
}

const cookiesAccepted = () => {
  let accepted = cookie.load(COOKIES_ACCEPTED_KEY)
  return accepted
}

const acceptCookies = () => {
  cookie.save(COOKIES_ACCEPTED_KEY, true)
}

const languageCodesFromLocales = (locs) => {
  let languages = []
  for(let i in locs) {
    let lang = locs[i].split('_')[0]
    if(languages.indexOf(lang) === -1) {
      languages.push(lang)
    }
  }
  return languages
}

const languageFromLocale = (locale) => {
  switch(locale) {
    case 'fr_FR':
      return { long: 'Français', code: 'fr' }
    case 'fr_CM':
      return { long: 'Français', code: 'fr' }
    case 'en_CM':
      return { long: 'English', code: 'en' }
    case 'en_GH':
      return { long: 'English', code: 'en' }
    case 'pl_PL':
      return { long: 'Polski', code: 'pl' }
    case 'fr_BE':
      return { long: 'Français', code: 'fr' }
    case 'nl_BE':
      return { long: 'Nederlands', code: 'nl' }
    default:
      return { long: '', code: '' }
  }
}

const updateUrlParams = (history, newParams, overwrite = false, replace = false) => {
  let params = {}
  if(!overwrite) {
    params = paramsFromSearch(history.location.search)
  }
  for(let key in newParams) {
    if(!!newParams[key] && newParams[key] !== '') {
      params[key] = newParams[key]
    } else {
      delete params[key]
    }
  }

  let newSearch = `?`
  for(let key in params) {
    if(newSearch !== '?') {
      newSearch += '&'
    }
    newSearch += `${key}=${params[key]}`
  }

  if(replace) {
    history.replace({
      pathname: history.location.pathname,
      search: newSearch
    })
  } else {
    history.push({
      pathname: history.location.pathname,
      search: newSearch
    })
  }
}

const paramsFromSearch = (search) => {
  if(search.length <= 2) {
    return {}
  }
  search = search.substring(1, search.length)
  let components = search.split('&')
  let params = {}
  for(let i in components) {
    let keyValue = components[i].split('=')
    params[keyValue[0]] = keyValue[1]
  }
  return params
}

const safeValue = (object, key, type = 'string') => {
  const keyComponents = key.split('.')
  let defaultValue
  if(type === 'string') {
    defaultValue = ''
  } else if(type === 'number') {
    defaultValue = 0
  } else if(type === 'array') {
    defaultValue = []
  } else if(type === 'object') {
    defaultValue = {}
  }
  let value = object
  for(let i in keyComponents) {
    if(!value) {
      return defaultValue
    }
    value = value[keyComponents[i]]    
  }
  if(!value) {
    return defaultValue
  }
  return value
}

const isVatValid = (s) => {
  let vatRegex = /^[0-9]{10}$/
  return s.match(vatRegex)
}

const isPhoneValid = (s) => {
  let vatRegex = /^[0-9]{8,}$/
  return s.match(vatRegex)
}

const isEmailValid = (s) => {
  let emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  return s.match(emailRegex)
}

export {
  sortedArrayFromObject,
  printDate,
  idFromName,
  t,
  languageFromLocale,
  setLanguage,
  LANGUAGE_C_KEY,
  currentLanguage,
  // ReactGA,
  updateUrlParams,
  paramsFromSearch,
  safeValue,
  languageCodesFromLocales,
  isVatValid,
  isEmailValid,
  isPhoneValid,
  cookiesAccepted,
  acceptCookies,
  constants,
  RECAPTCHA_SITE_KEY,
}