import React, { useState, useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel';

import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'

import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog'

import { Helmet, HelmetProvider } from "react-helmet-async"

import { Router } from 'react-router-dom'
import history from './services/history'
import Routes, { isPathPrivate, isPathOnlyUnauthorized, defaultAuthorizedPath, defaultUnauthorizedPath } from './routes' 

import { createUserStateChangedListener, isUserSignedIn, signOut } from './services/auth'

import { NavigatorPage } from './modules'

import * as database from './services/database'

import { t, setLanguage, languageFromLocale, currentLanguage, acceptCookies, cookiesAccepted } from './utils'

import { Auth, ScrollToTop, Cookie, CookiesSection } from './components'

import config from './config.json'

import interactions from './utils/interactions.json'

import { useIdleTimer } from 'react-idle-timer'

const ix2 = window.Webflow?.require('ix2')
// const webflow = window.Webflow

const Alert = (props) => {
  return (<MuiAlert elevation={6} variant="filled" {...props} />)
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3EA447',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FF6500'
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif;'
  }
})

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 99999,
    color: '#fff',
  },
}));

if(config.partner === 'axa') {
  ReactPixel.init('645199742809810');
  ReactPixel.pageView();
}else if (config.partner === 'lv') {
  // ReactPixel.init('2604267593196031');
  // ReactPixel.pageView();
}

function App() {

  const [userData, setUserData] = useState({})
  const [topics, setTopics] = useState({})
  const [questions, setQuestions] = useState({})
  const [appointments, setAppointments] = useState({})
  const [partner, setPartner] = useState(null)
  const [selectedLocale, setSelectedLocale] = useState('fr_BE')

  const [publicListeners, setPublicListeners] = useState({})

  const [cookiesAcc, setCookiesAcc] = useState(false) 

  const [snackbar, setSnackbar] = useState({ open: false, text: '', color: 'info' })

  const [loading, setLoading] = useState({
    authStatus: { isLoading: true }
  })

  const [signedIn, setSignedIn] = useState(false)

  const [auth, setAuth] = useState({
    isOpen: false,
    content: 'login',
    onRegister: null,
    onLogin: null
  })

  const [userDataFetched, setUserDataFetched] = useState(false)

  const handleOnIdle = () => {
    signOut()
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle
  })


  const refreshInteractions = (force = false) => {
    if(!!ix2 && (!Boolean(isLoading()) || force)) {
      ix2.init(interactions)
      window.scrollBy({ 
        top: Math.random() > 0.5 ? window.scrollY > 0 ? -1 : 1 : 1,
        left: 0,
        behavior: 'smooth'})
    }
  }

  const setLocale = (locale) => {
    setLanguage(languageFromLocale(locale).code)
    setSelectedLocale(locale)
  }

  const fetchPublicData = () => {
    fetchTopics()
    fetchQuestions()
    fetchPartner()
  }
  

  const fetchUserData = async () => {
    // startLoading('user')
    setUserDataFetched(false)
    let data = await database.fetchUserData()
    if(!data.error) {
      setUserData(data)
    }
    setUserDataFetched(true)
    // stopLoading('user')
  }

  const fetchAppointments = async () => {
    // startLoading('userAppointments')
    let data = await database.fetchAppointments()
    if(!data.error) {
      setAppointments(data)
    }
    // stopLoading('userAppointments')
  }

  const fetchPartner = async () => {
    // startLoading('partner')
    let data = await database.fetchPartner()
    if(!data.error) {
      setPartner(data)
    } else {
      console.log('fetch partner error', data.error)
    }
    stopLoading('partner')
  }

  const fetchTopics = async () => {
    // startLoading('topics')
    let data = await database.fetchTopics()
    if(!data.error) {
      setTopics(data)
    } else {
      console.log('fetch topics error', data.error)
    }
    // stopLoading('topics')
  }

  const fetchQuestions = async () => {
    // startLoading('questions')
    let data = await database.fetchQuestions()
    if(!data.error) {
      setQuestions(data)
    } else {
      console.log('fetch questions error', data.error)
    }
    // stopLoading('questions')
  }

  const fetchPrivateData = () => {
    fetchUserData()
    fetchAppointments()
  }

  const clearPrivateData = () => {
    setUserData({})
    setAppointments({})
  }

  const navigate = (path) => () => {
    history.push(path)
  }

  const shouldAcceptCookies = () => {
    acceptCookies()
    setCookiesAcc(true)
  }

  useEffect(() => {
    const languageBrowser = navigator.language || navigator.userLanguage
    console.log('languageBrowser :>> ', languageBrowser);
    if (languageBrowser === 'fr-BE' || languageBrowser === 'fr-FR' || languageBrowser === 'fr-CM' || languageBrowser === 'fr-CA' || languageBrowser === 'fr-CH' || languageBrowser === 'fr') {
      setLocale('fr_BE')
    } else if (languageBrowser === 'nl-BE' || languageBrowser === 'nl') {
      setLocale('nl_BE')
    }
    else {
      setLocale(selectedLocale)
    }

    // if(partner) {
    //   for(let i in partner.locales) { 
    //     if(languageFromLocale(partner.locales[i]).code === currentLanguage()) {
    //       setLocale(partner.locales[i])
    //       break
    //     }
    //   }
    // }

    setCookiesAcc(cookiesAccepted())

    let pl = publicListeners

    if(!pl.user) {
      pl.user = createUserStateChangedListener((user) => {

        setSignedIn(!!user)

        const { pathname } = history.location

        if(!!user) {
          fetchPublicData()
          fetchPrivateData()
          if(isPathOnlyUnauthorized(pathname)) {
            history.push(defaultAuthorizedPath)
          }
        } else {
          clearPrivateData()
          fetchPublicData()

          if(isPathPrivate(pathname)) {
            history.push(defaultUnauthorizedPath)
          }
        }
        stopLoading('authStatus')
      })
      setPublicListeners(pl)
    }
    
    history.listen(location => {
      const { pathname } = location
      if(isUserSignedIn() && isPathOnlyUnauthorized(pathname) ) {
        history.push(defaultAuthorizedPath)
        return
      } else if(!isUserSignedIn() && isPathPrivate(pathname)) {
        history.push(defaultUnauthorizedPath)
        return
      }  
    })
  }, [partner])

  const showSnackbar = ({ text, color }) => {
    setSnackbar({ open: true, text, color})
  }

  const hideSnackbar = () => {
    setSnackbar({...snackbar, open: false })
  }

  const startLoading = (key, message) => {
    let l = loading
    l[key] = { isLoading: true, message: message }
    
    setLoading({...l})
  }

  const stopLoading = (key) => {
    let l = loading
    l[key] = { isLoading: false }
    let entirelyStopped = true
    for(let key in l) {
      if(l[key].isLoading) {
        entirelyStopped = false
        break
      }
    }
    if(entirelyStopped) {
      refreshInteractions(true)
    }
    setLoading({...l})
  }

  const isLoading = () => {
    for(let key in loading) {
      if(Boolean(loading[key].isLoading)) {
        return key
      }
    }
    return null
  }

  const showAuth = (content, onLogin, onRegister) => {
    setAuth({
      isOpen: true,
      content,
      onLogin: () => {
        hideAuth()
        if(onLogin) {
          onLogin()
        }
      },
      onRegister: () => {
        hideAuth()
        if(onRegister) {
          onRegister()
        }
      },
    })
  }

  const hideAuth = () => {
    setAuth({
      isOpen: false,
      onRegister: null,
      onLogin: null
    })
  } 

  const hideCookie = () => {
    setCookiesAcc(false)
  }

  const classes = useStyles()

  const cookieBannerStyles = {
    banner: {
      fontFamily: 'Source Sans Pro',
      height: 57,
      background: 'rgba(52, 64, 81, 0.88) url(/cookie.png) 20px 50% no-repeat',
      backgroundSize: '30px 30px',
      backgroundColor: '',
      fontSize: '15px',
      fontWeight: 600
    },
    button: {
      border: '1px solid white',
      borderRadius: 4,
      width: 66,
      height: 32,
      lineHeight: '32px',
      background: 'transparent',
      color: 'white',
      fontSize: '14px',
      fontWeight: 600,
      opacity: 1,
      right: 20,
      marginTop: -18
    },
    message: {
      display: 'block',
      padding: '9px 67px',
      lineHeight: 1.3,
      textAlign: 'left',
      marginRight: 244,
      color: 'white'
    },
    link: {
      textDecoration: 'none',
      fontWeight: 'bold'
    }
  }

  const renderAppPlaceholder = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <h3>Coming soon</h3>
        <br/>
        <span>My Legal Expert is under construction</span>
      </div>
    )
  }
  
  return (
    <HelmetProvider context={{}}>
      <Helmet>
        <title>{ config.name }</title>
        <meta name="description" content={ config.description } />
        <link href="/images/favicon.png" rel="shortcut icon" type="image/x-icon" />
        <link href="/images/webclip.png" rel="apple-touch-icon" />
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-3057324-47"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'UA-3057324-47');
          gtag('config', 'UA-179952362-3');
          `}
        </script> */}
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* { renderAppPlaceholder() } */}
        <Router history={history}>
          <ScrollToTop />
          <NavigatorPage
            history={history}
            showSnackbar={showSnackbar}
            startLoading={startLoading}
            stopLoading={stopLoading}
            signedIn={signedIn}
            showAuth={showAuth}
            refreshInteractions={refreshInteractions}
            userData={userData}
            isLoading={Boolean(isLoading())}
            setLocale={setLocale}
            partner={partner}
            >
            {/* { renderAxaHeader() } */}
            <Routes 
              history={history}
              showSnackbar={showSnackbar}
              startLoading={startLoading}
              stopLoading={stopLoading}
              showAuth={showAuth} 
              fetchUserData={fetchUserData}
              fetchAppointments={fetchAppointments}
              userData={userData}
              topics={topics}
              questions={questions}
              appointments={appointments}
              partner={partner}
              refreshInteractions={refreshInteractions} 
              selectedLocale={selectedLocale}
              userDataFetched={userDataFetched} />
          </NavigatorPage>
        </Router>
        <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={hideSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert 
            onClose={hideSnackbar} 
            color={snackbar.color}>
            {snackbar.text}
          </Alert>
        </Snackbar>
        
        <Dialog
          scroll="body"
          maxWidth="lg"
          onEscapeKeyDown={hideAuth}
          onClose={hideAuth}
          open={auth.isOpen}>
              <Auth 
                content={auth.content}
                onLogin={auth.onLogin}
                onRegister={auth.onRegister}
                showSnackbar={showSnackbar}
                startLoading={startLoading}
                stopLoading={stopLoading}   
                fetchUserData={fetchUserData}
                />
        </Dialog>

        {/* {!cookiesAcc && config.partner === 'axa' && <Dialog
          scroll="body"
          maxWidth="lg"
          onEscapeKeyDown={hideCookie}
          onClose={hideCookie}
          open={!cookiesAcc}>
            <Cookie
              shouldAcceptCookies={shouldAcceptCookies}
              partner={partner}     
            />
        </Dialog>} */}
        {!cookiesAcc && <CookiesSection
          shouldAcceptCookies={shouldAcceptCookies}
          partner={partner}     
        />}

        <Backdrop className={classes.backdrop} open={Boolean(isLoading())}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {/* <Fade in={!!loading[isLoading()]}> */}
              <span style={{ marginBottom: 20 }}>{ !!loading[isLoading()] ? loading[isLoading()].message : '' }</span>
            {/* </Fade> */}
            <CircularProgress color="primary" />            
          </div>
        </Backdrop>
      </ThemeProvider>
      
    </HelmetProvider>
  );
}

export default App;

// { !cookiesAcc ? (
//   <div className="cookie-banner-wrapper">
//     <span className="cookie-banner-text">{ t('cookie_banner.text') }<a href="#" onClick={navigate('/cookies')} className="cookie-banner-link">{ t('cookie_banner.link') }</a></span>
//     <div className="cookie-banner-buttons-wrapper">
//       <div onClick={shouldAcceptCookies} className="cookie-banner-button">{ t('cookie_banner.accept') }</div>
//       {/* <div onClick={acceptCookies} className="cookie-banner-button-plain">{ t('cookie_banner.decline') }</div> */}
//     </div>
//   </div>
//   // <div className="cookie_background">
//   //   <div className="cookie-banner-wrapper">
//   //     <div className="cookie_text">
//   //       <h1 className="h4">{ t('cookies.title') }</h1>
//   //       <h5 className="cookie__h5">{ t('cookies.text') }</h5>
//   //     </div>
//   //     <div className="cookie_buttons_choice">
//   //       <div className="link-box link-box--submit w-inline-block cookie_button_left">
//   //         <p className="link-box__title voucher_code_button_text">{ t('cookies.allow_selection') }<br/></p>
//   //       </div>  
//   //       <div className="link-box link-box--submit w-inline-block link-box--green">
//   //         <p className="link-box__title voucher_code_button_text">{ t('cookies.allow_all') }<br/></p>
//   //       </div>
//   //     </div>
//   //     <div className="cookie_button_more">
//   //       <div className="link-box link-box--cookie w-inline-block link-box--blue">
//   //         <p className="link-box__title voucher_code_button_text">{ t('cookies.allow_necessary') }<br/></p>
//   //       </div> 
//   //     </div>
//   //   </div>
//   // </div>
//   ) : null }